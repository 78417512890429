import React from 'react'
import { NavLink,Link } from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_gettaskoop,API_refreshoop } from '../api/task';
import { Table,Menu,Button,Spin,notification,Form,Select} from 'antd';
import { API_Online } from '../api/getheader';
import {spandata} from './../api/addon';
import getColumnSearchProps from './../components/getColumnSearch'

const routes = [{ label: 'Главная', path: '/' }, { label: 'ОПОП', path: '/task' }]

function updatefromgoogle() {
    if ((localStorage.getItem('login')==='admin')||(localStorage.getItem('login')==='ip.bogushevich')) { 
        return (
        <Button onClick={() => API_refreshoop()}   type="primary">Обновление из Облака</Button>
        )
     }
}
const TaskOOP = props  => {
    const { Option } = Select;
    var now = new Date();
    var year=now.getFullYear();
    var setyear=0;
    if (localStorage.getItem('year')>0) {
        setyear=localStorage.getItem('year');
    } else {
        setyear=year;
    }
    const [choiceyear,choiceyearvalue]= useState(setyear);
    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Государственная итоговая аттестация\" , доступ имеют секретарри,техподдержка,зав.кфедры,зам.деканы по уч.работе, руководители подразделений" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1 `+error, description: error });
                
            } catch {
                notification.error({ message: `Ошибка 2 `, description: error });
            }
        });;
    };
    useEffect(() => {
        if (localStorage.getItem('year')!==choiceyear) { 
            notification.info({ message: `Выбран год отчета: `+choiceyear, description: "" }); 
            localStorage.setItem('year', choiceyear);
        }    
            getOnline();
            
    // eslint-disable-next-line            
    }, [choiceyear]);
    
    

    const [loading, setLoading] = useState(false);     // для индикации спинера крутилки
    const [taskinfo, settaskinfo] = useState([]);
    
    const columns = [
        {
            title: '№',
            key: 'rowNumber',
            dataIndex: 'rowNumber',
        },
        {
            title: 'УЧП',
            dataIndex: 'fak',
            key: 'fak',
            ...getColumnSearchProps('fak','УЧП'),
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code','Код направления'),
        },
        
        {
            title: 'Направление',
            dataIndex: 'napr',
            key: 'napr',
            ...getColumnSearchProps('napr','Направление'),
        },
        
        {
            title: 'Профиль',
            dataIndex: 'profile',
            key: 'profile',
            ...getColumnSearchProps('profile','Профиль'),
        },
        
        {
            title: 'Группы',
            key: 'groupnames',
            dataIndex: 'groupnames',
            
    },
    
        {
            title: 'Очная форма',
            key: 'totalofo',
            render: taskinfo=> {
                if (taskinfo.ofo>0) {
                        return <Link 
                        to={{
                          pathname: `/tasksingle/${taskinfo.id}/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata(taskinfo.ofo,11)}
                        </Link>
                    }
                }
            
    },
    
    {
        title: 'Очно-заочная',
        key: 'ozf',
        render: taskinfo=> {
            if (taskinfo.ozf>0) {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.ozf,11)}
            </Link>
        }
    }
        
    },
    
    {
        title: 'Заочная форма',
        key: 'totalzfo',
        render: taskinfo=> {
            if (taskinfo.zf>0) {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zf,11)}
            </Link>
        }
    }
    },
    
        {
            title: 'Даты ГЭ',
            key: 'datesge',
            dataIndex: 'datesge'
            
        },
        {
            title: 'Даты ВКР',
            key: 'datesvkr',
            dataIndex: 'datesvkr'
            
        },
        {
            title: 'Секретарь',
            key: 'secretargak',
            dataIndex: 'sekretargak'
            
        },
        {
            title: 'Отв. тех. сопр.',
            key: 'otvgia',
            dataIndex: 'otvgia'
            
        },
        {
            title: 'Зав.каф',
            key: 'zavkaf',
            dataIndex: 'zavkaf'
            
        },
    
    ];
    
    useEffect(() => {
        
        setLoading(true);
        const idtask = parseInt(props.match.params.id, 10);
        
        API_gettaskoop(idtask,choiceyear).then(data => {
            console.log('-'+choiceyear+'-');
            let index = 0;
            settaskinfo(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        })
        .finally(() => setLoading(false));
    }, [props.match.params.id,choiceyear]);

       return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Основные профессиональные образовательные программы" />
            <Form id="30">
            <Form.Item label="Отчетный год:">
                <Select defaultValue={choiceyear} onChange={choiceyearvalue} style={{ width: 300 }}>
					<Option value="2027">2027</Option>
					<Option value="2026">2026</Option>
					<Option value="2025">2025</Option>
					<Option value="2024">2024</Option>
					<Option value="2023">2023</Option>
                    <Option value="2022">2022</Option>
                    <Option value="2021">2021</Option>
                    <Option value="2020">2020</Option>
                </Select>
                </Form.Item>
            </Form>   
            <Menu
                            mode="horizontal"
                            style={{ height: '100%' }}
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            
                            <Menu.Item key="bak">
                                <NavLink to="/task/03">
                                    Бакалавриат
                                </NavLink>
                            </Menu.Item>
                             
                            <Menu.Item key="/spec">
                                <NavLink to="/task/05">
                                    Специалитет
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/mag">
                                <NavLink to="/task/04">
                                    Магистратура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/asp">
                                <NavLink to="/task/06">
                                    Аспирантура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/ord">
                                <NavLink to="/task/08">
                                    Ординатура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spo" >
                                <NavLink to="/task/02">
                                    СПО
                                </NavLink>
                            </Menu.Item>
                        </Menu>
        <Spin  spinning={loading} tip="Загрузка..">
            <Table columns={columns} dataSource={taskinfo} bordered size="small" pagination={false}
                rowKey ={record => record.id} 
                />
        </Spin>    
                { updatefromgoogle() }
        </>
        

    )
}

export default TaskOOP
